import { createApp } from 'vue'
import App from './App.vue'

import {createRouter, createWebHistory} from 'vue-router'
//--------------- END ALL LIBRARY MODULES ------------------//

// Routes
import routes from './routes'

import GlobalMixin from './globalMixin'

window.$ = window.jQuery = require('jquery');
window.axios = require('axios');
window.axios.defaults.baseURL = "https://adminusaha.com/api/captransfer";
//window.axios.defaults.headers.common['X-Requested-With'] = 'NotAJAX';




// Router instance
const router = createRouter({
    history: createWebHistory(),
    routes,
})

// Init App

const app = createApp(App)
app.mixin(GlobalMixin) // end mixin

// Init router into app
app.use(router)

// Mount the App
app.mount('#app')

// Done.....!

